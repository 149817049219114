import React from "react";

import { RatingWidget } from "../RatingWidget";

import * as styles from "./Content.module.scss";

interface Props {
  title: string;
  subtitle?: string;
  body: string;
  rating?: {
    breakfast: number;
    service: number;
    room: number;
    location: number;
  };
}

const Content: React.FC<Props> = ({ body, title, rating, subtitle }: Props) => (
  <div className={styles.content}>
    <h1 className={styles.title}>{title}</h1>
    <div className={styles.subtitle}>{subtitle}</div>
    {rating && <RatingWidget rating={rating} />}
    <div className={styles.body} dangerouslySetInnerHTML={{ __html: body }} />
  </div>
);

export default Content;
