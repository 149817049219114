import React from "react";

import { Rating, ratingToIcon } from "@/constants";

import * as styles from "./RatingWidget.module.scss";

const SingleRating = ({
  ratingKind,
  value,
}: {
  ratingKind: Rating;
  value: number;
}) => (
  <div className={styles.ratingWidget}>
    <div className={styles.ratingTitle}>{ratingKind}</div>
    <div className={styles.ratingValue}>{value}</div>
    <div className={styles.ratingIcon}>{ratingToIcon[ratingKind]}</div>
  </div>
);

type Props = {
  rating: Record<Rating, number>;
};

const RatingWidget = ({ rating }: Props) => (
  <div className={styles.ratingContainer}>
    <div className={styles.ratingContent}>
      {Object.entries(rating).map(([key, value]) => (
        <SingleRating ratingKind={key as Rating} value={value} key={key} />
      ))}
    </div>
  </div>
);

export default RatingWidget;
